import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import App from "src/app";
import {BrowserRouter} from "react-router-dom";

const delayForMockToFinishLoading = 1000; //milliseconds

function maybeStartMocks() {
  if (import.meta.env.VITE_FINAPP_MOCKS_ENABLED === "true") {
    return import("./mocks/browser")
      .then(({worker}) => worker.start())
      .then(() => new Promise((resolve) => setTimeout(resolve, delayForMockToFinishLoading)));
  }
  return Promise.resolve();
}

(async function bootstrap() {
  await maybeStartMocks();

  ReactDOM.createRoot(document.getElementById("app")!).render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
})();
